<template>
  <div class="alert-banner-component">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AlertBanner',
}
</script>

<style lang="scss">
  .alert-banner-component {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    min-height: 50px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparentize($ct-ui-color-23, 0.2);
    background: transparentize($ct-ui-color-23, 0.85);

    a {
      font-weight: 700;
      color: transparentize($ct-ui-color-23, 0.2);
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 660px) {
    .alert-banner-component {
      padding: 1.0em;
    }
  }
</style>
